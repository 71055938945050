import { ApolloQueryResult } from '@apollo/client';
import { client as clientv2 } from '@apollo-client-v2';
import { DocumentStatus, PostFeedPage } from '@ui-kit/main-api-types';

import { useUserStore } from '@store/user';

import mutations from './mutations';
import queries from './queries';

interface IGetUserPosts {
	page: number;
	userId: string;
	isDrafts: boolean;
}

export const getUserPosts = async ({
	isDrafts,
	userId,
	page,
}: IGetUserPosts) => {
	const user = useUserStore.getState().user;
	const authToken = user?.authToken;
	const postsQuery: ApolloQueryResult<{
		postQueries: { feed: PostFeedPage; currentUserFeed: PostFeedPage };
	}> = await clientv2.query({
		query: isDrafts ? queries.getCurrentUserPostList : queries.getPostList,
		variables: {
			...(!isDrafts && { author: [userId] }),
			page,
			pageSize: 10,
			status: isDrafts ? DocumentStatus.Unpublished : DocumentStatus.Published,
		},
		context: {
			headers: {
				'x-auth-token': authToken,
			},
		},
		errorPolicy: 'all',
	});
	return postsQuery;
};

export const deletePost = postId => {
	const user = useUserStore.getState().user;
	const authToken = user?.authToken;
	return clientv2.mutate({
		mutation: mutations.deletePost,
		variables: {
			postId,
		},
		context: {
			headers: {
				'x-auth-token': authToken,
			},
		},
	});
};
