import { gql } from '@apollo/client';

import {
	LOCALIZED_BLOG_POST_DESCRIPTION_FRAGMENT,
	LOCALIZED_SB_FRAGMENT,
	LOCALIZED_STRING_FRAGMENT,
	TAGS_QUERIES_ARTICLE,
} from '@store/fragments';

const COMMENT_FRAGMENT = gql`
	fragment CommentFragmentPosts on Comment {
		id
		text
		created
		objectId
		isDeleted
		edited
		isReplyToTopLevelComment
		user {
			name
			avatar {
				url
			}
			id
			roles {
				ID
			}
		}
		objectClass
		reactions {
			userReaction {
				objectClass
				objectID
				reactionType
			}
			likeCount
			dislikeCount
		}
		threadCommentsCount
		childThread(
			input: { sort: OLDEST, pagination: { ByCursor: { first: 3 } } }
		) {
			items {
				id
				text
				created
				threadId
				edited
				isDeleted
				isReplyToTopLevelComment
				objectClass
				objectId
				user {
					id
					name
					avatar {
						url
					}
					roles {
						ID
					}
				}
				reactions {
					userReaction {
						objectClass
						objectID
						reactionType
					}
					likeCount
					dislikeCount
				}
				parentComment {
					id
					isDeleted
					user {
						id
						name
					}
				}
				threadComment {
					id
					user {
						id
						name
					}
				}
			}
			paginationInfo {
				... on PaginationByCursor {
					totalItemsCount
					endCursor
					hasNextPage
				}
			}
		}
	}
`;

const POST_AUTHOR_FRAGMENT = gql`
	fragment postAuthor on Author {
		user {
			id
			nickname
			name
			avatar {
				url
			}
		}
	}
`;

const POST_FRAGMENT = gql`
	${LOCALIZED_STRING_FRAGMENT}
	${LOCALIZED_BLOG_POST_DESCRIPTION_FRAGMENT}
	${POST_AUTHOR_FRAGMENT}
	fragment Post on Post {
		id
		hru
		title {
			...localizedString
			defaultValue(language: $language)
		}
		mainPhoto {
			url
		}
		publicationTime
		commentsCount
		link
		url(input: { useAvailableLang: true })
		reactions {
			userReaction {
				objectClass
				objectID
				reactionType
			}
			likeCount
			dislikeCount
		}
		mainSport {
			path
			id
		}
		author {
			...postAuthor
		}
		displayAuthor {
			...postAuthor
		}
		status
		label
		blog {
			id
			hru
			title
			avatar {
				url
			}
			founder {
				id
				name
				nickname
			}
		}
		structuredDescription {
			...localizedBlogPostDescription
		}
		structuredBody {
			...localizedBlogPostDescription
		}
	}
`;

export default {
	getPostList: gql`
		${POST_FRAGMENT}
		query getPostList(
			$tag: TagIDsInput
			$page: Int = 1
			$pageSize: Int = 10
			$language: Language
			$blogID: String
			$main: Boolean
			$mainInSection: Boolean
			$status: DocumentStatus = PUBLISHED
			$sport: [SportID!]
			$author: [ID!]
			$minRating: Int
			$sort: PostSortInput
		) {
			postQueries {
				feed(
					input: {
						tag: $tag
						pagination: { ByPage: { page: $page, pageSize: $pageSize } }
						language: $language
						main: $main
						mainInSection: $mainInSection
						blogID: $blogID
						status: $status
						sport: $sport
						author: $author
						minRating: $minRating
						sort: $sort
					}
				) {
					items {
						...Post
					}
					paginationInfo {
						... on PaginationByPage {
							currentPage
							lastPage
							totalItemsCount
						}
					}
				}
			}
		}
	`,
	getPopularFeedPosts: gql`
		${POST_FRAGMENT}
		query getPopularFeedPosts(
			$tag: TagIDsInput
			$page: Int = 1
			$pageSize: Int = 10
			$sport: SportID
		) {
			postQueries {
				feedPopular(
					input: {
						tag: $tag
						pagination: { ByPage: { page: $page, pageSize: $pageSize } }
						sport: $sport
					}
				) {
					items {
						...Post
					}
					paginationInfo {
						... on PaginationByPage {
							currentPage
							lastPage
							totalItemsCount
						}
					}
				}
			}
		}
	`,
	getPopularNotEditorialPostList: gql`
		${LOCALIZED_STRING_FRAGMENT}
		${LOCALIZED_BLOG_POST_DESCRIPTION_FRAGMENT}
		${POST_AUTHOR_FRAGMENT}
		query getPopularNotEditorialPostList(
			$page: Int = 1
			$pageSize: Int = 7
			$sport: SportID
			$minRating: Int = 15
		) {
			postQueries {
				feedNonEditorialPopular(
					input: {
						pagination: { ByPage: { page: $page, pageSize: $pageSize } }
						sport: $sport
						minRating: $minRating
					}
				) {
					items {
						id
						hru
						title {
							...localizedString
						}
						publicationTime
						commentsCount
						link
						reactions {
							likeCount
							dislikeCount
						}
						mainSport {
							path
							id
						}
						author {
							...postAuthor
						}
						blog {
							id
							hru
							title
						}
						structuredDescription {
							...localizedBlogPostDescription
						}
					}
				}
			}
		}
	`,
	getCurrentUserPostList: gql`
		${LOCALIZED_STRING_FRAGMENT}
		${LOCALIZED_BLOG_POST_DESCRIPTION_FRAGMENT}
		${POST_AUTHOR_FRAGMENT}
		query getCurrentUserPostList(
			$page: Int = 1
			$pageSize: Int = 10
			$status: DocumentStatus = PUBLISHED
		) {
			postQueries {
				currentUserFeed(
					input: {
						pagination: { ByPage: { page: $page, pageSize: $pageSize } }
						status: $status
					}
				) {
					items {
						id
						hru
						title {
							...localizedString
						}
						mainPhoto {
							url
						}
						publicationTime
						createdTime
						commentsCount
						link
						reactions {
							userReaction {
								objectClass
								objectID
								reactionType
							}
							likeCount
							dislikeCount
						}
						mainSport {
							path
							id
						}
						author {
							...postAuthor
						}
						displayAuthor {
							...postAuthor
						}
						status
						label
						blog {
							id
							hru
							title
							avatar {
								url
							}
							founder {
								id
								name
								nickname
							}
						}
						structuredDescription {
							...localizedBlogPostDescription
						}
					}
					paginationInfo {
						... on PaginationByPage {
							currentPage
							lastPage
							totalItemsCount
						}
					}
				}
			}
		}
	`,
	getPostByHru: gql`
		${COMMENT_FRAGMENT},
		${TAGS_QUERIES_ARTICLE},
		${LOCALIZED_SB_FRAGMENT},
		${POST_AUTHOR_FRAGMENT}
		query getPostByHru($id: ID!) {
			postQueries {
				get(input: {id: $id}) {
					id
					hru
					author {
						...postAuthor
					}
					displayAuthor {
						...postAuthor
					}
					mainPhoto {
						url
						meta {
							height
							width
						} 
					}
					topPhoto {
						url
						meta {
							height
							width
						} 
					}
					status
					languages
					mainSport {
						id
						name {
							...localizedString
						}
						path
					}
					sports {
						id
						path
						name {
							...localizedString
						}
					}
					mainSport {
						id
						path
					}
					tags {
						...TagsQueryFragmentForArticle
					}
					structuredBody {
						...LocalizedSB
					}
					structuredDescription {
						...LocalizedSB
					}
					commentsDisabled
					title {
						...localizedString
					}
					blog {
						id
						hru
						title
						isBranded
						avatar {
							url
							meta {
								width
								height
							}
						}
						founder {
							nickname
						}
					}
					label
					publicationTime
					createdTime
					modifiedTime
					commentsCount
					link
					reactions {
						userReaction {
							objectClass
							objectID
							reactionType
						}
						likeCount
						dislikeCount
					}
					topLevelComments(input: {sort: BEST, pagination: { ByCursor: { first: 50 } }} ) {
						items {
							...CommentFragmentPosts
						}
						paginationInfo {
							... on PaginationByCursor {
								totalItemsCount
								hasNextPage
								endCursor
							}
						}
					}
					seoTitle {
						...localizedString
					}
					seoDescription {
						...localizedString
					}
					seoCanonical {
						...localizedString
					}
					shareInfo {
						title
						picture {
							url
						}
					}
				}
			}
		}`,
	getTopPost: gql`
		${LOCALIZED_STRING_FRAGMENT}
		${LOCALIZED_BLOG_POST_DESCRIPTION_FRAGMENT}
		query getTopPost(
			$page: Int = 1
			$pageSize: Int = 10
			$language: Language
			$main: Boolean
			$top: Boolean
			$sportType: [SportID!]
			$status: DocumentStatus = PUBLISHED
		) {
			postQueries {
				feed(
					input: {
						pagination: { ByPage: { page: $page, pageSize: $pageSize } }
						language: $language
						main: $main
						top: $top
						status: $status
						sport: $sportType
					}
				) {
					items {
						id
						hru
						title {
							...localizedString
						}
						publicationTime
						top
						likesCount
						commentsCount
						link
						mainPhoto {
							url
						}
						mainSport {
							path
							id
						}
						topPhoto {
							url
						}
						blog {
							id
							hru
							title
							avatar {
								url
							}
							founder {
								id
								name
								nickname
							}
						}
						likesCount
						commentsCount
						status
						label
						structuredDescription {
							...localizedBlogPostDescription
						}
					}
					paginationInfo {
						__typename
						... on PaginationByPage {
							currentPage
							lastPage
						}
					}
				}
			}
		}
	`,
	getUserPostsCount: gql`
		query getPostList($page: Int = 1, $pageSize: Int = 10, $author: [ID!]) {
			postQueries {
				feed(
					input: {
						pagination: { ByPage: { page: $page, pageSize: $pageSize } }
						author: $author
					}
				) {
					paginationInfo {
						... on PaginationByPage {
							totalItemsCount
						}
					}
				}
			}
		}
	`,
};
