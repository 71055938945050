import { gql } from '@apollo/client';

export default {
	createPost: gql`
		mutation CreatePost($post: PostCreateInput!) {
			postMutations {
				create(input: $post) {
					created {
						id
					}
				}
			}
		}
	`,
	createEmptyPost: gql`
		mutation CreateEmptyPost {
			postMutations {
				createEmpty
			}
		}
	`,
	updatePost: gql`
		mutation UpdatePost($post: PostUpdateInput!) {
			postMutations {
				update(input: $post) {
					updated {
						id
					}
				}
			}
		}
	`,
	deletePost: gql`
		mutation DeletePost($postId: ID!) {
			postMutations {
				delete(input: { id: $postId }) {
					id
				}
			}
		}
	`,
};
